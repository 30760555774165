.el__products {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  overflow: hidden;
}

.float-left {
  float: left;
}

.el_header {
  text-align: center;
  color: white;
  margin: 0 0 25px;
  font-size: 1.5em;
}

.el_products_inner {
  width: 100%;
  display: flex;
  transition: transform 0.5s ease;
  transform: scale(0);
}

.el_products_inner.active {
  transform: scale(1);
}

.bg--overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
  opacity: 0.3;
  display: none;
  z-index: 99;
}
.bg--overlay.show {
  display: block;
}
.el__close-btn.hide {
  display: none;
}

h1.el_header.hidden {
  visibility: hidden !important;
}

.el__text.active.products {
  max-width: 1030px;
}

.products {
  max-width: 1050px;
  font-size: 18px;
  color: #fff;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  transform: scale(0);
  transition: all 0.3s ease;
}

.el_products_inner_content {
  width: 100%;
  display: flex;
  transition: transform 1s ease;
  padding: 5px;
}

.el_products_product {
  width: 100%;
  min-height: 400px;
  background-color: #fff;
  color: #1f1f1f;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: transform 0.3s, visibility 0.5s, opacity 0.5s linear;
  text-align: center;
}
.el_products_product:hover {
  box-shadow: 0px 0px 0px 5px gold;
  cursor: pointer;
}

.el_products_product .product_image {
  height: 260px;
  margin-bottom: auto;
}
.el_products_product .product_image img {
  width: 100%;
  object-fit: cover;
  max-height: 260px;
}

.el_products_product:first-child {
  margin-right: 1rem;
}

.el_products_product .product_content {
  /*height: 200px;*/
  font-size: 16px;
  margin-bottom: auto;
  padding: 16px;
}

.el_products_product .title {
  margin-bottom: 1rem;
}

@media screen and (max-width: 992px) {
  .el__text {
    height: auto;
  }

  .el__content {
    justify-content: flex-start;
    padding-top: 20px;
  }
  .el_products_inner_content {
    flex-direction: column;
  }

  .el_products_product {
    margin: 20px 0;
  }

  .products {
    height: 100%;
  }

  /* .pr__page_content .pr__text {
    overflow-y: auto;
  } */
  .pr__page_content {
    height: auto;
    overflow: hidden;
  }
}

.products.active {
  transform: scale(1);
}

.el__text.active.products.selected-product {
  max-width: 1230px;
}

.el__text.active.products h1 {
  align-self: flex-start;
}
.fixing-positions {
  margin-top: auto;
}
.el__products.full-size {
  width: 100%;
  /*height: 100%;*/
}

.category--el {
  color: #ffffff;
  cursor: pointer;
  margin: 5px 0px;
}

.el__text.active.products h1 {
  width: 100%;
  display: flex;
  /* flex-grow: 1; */
}

.active-category {
  position: relative;
  color: green;
  cursor: pointer;
  border-bottom: 1px solid red !important;
}

/* .active-category:before {
  position: absolute;
  content: ' ';
  left: -20px;
  border-left: 2px solid red;
  border-top: 2px solid red;
  width: 10px;
  height: 10px;
  transform: rotate(135deg);
  top: 4px;
} */

.products.active .el_header {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0;
  margin-bottom: 10px;
  padding: 0px 17px;
}

h1.el_header > span {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 15px;
}

h1.el_header > span > div {
  padding: 5px 10px;
  /* background: white; */
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  margin: 0 5px;
  font-size: 1rem;
}

.el__text.active a {
  color: white;
  line-height: 2rem;
}

@media screen and (max-width: 992px) {
  .cont {
    height: 100% !important;
  }
  .cont .el {
    width: 100% !important;
    height: 25vh;
    left: unset !important;
    transform-origin: unset !important;
    transform: unset !important;
    top: unset !important;
    position: relative !important;
    transition: transform 0.6s 0.7s, width 0.7s, opacity 0.6s 0.7s,
      z-index 0s 1.3s;
    will-change: transform, width, height, opacity;
  }

  .cont__inner {
    display: flex;
    flex-direction: column;
    max-height: 200vh;
  }

  .cont .el .el__bg {
    transform: unset !important;
  }
  .cont .el.s--active {
    height: 100%;
    position: absolute !important;
  }
  span.view-image {
    display: none;
  }
  figure img {
    max-width: calc(100vw - 90px);
    height: auto;
  }

  .products.active .el_header {
    font-size: 1.2rem;
  }

  .speed-dial {
    top: 100px;
    right: -10px !important;
    width: 200px;
    pointer-events: none;
    /* bottom: 0px; */
    /* right: unset; */
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px !important;
  }

  .speed-dial--active .speed-dial__button--holder {
    margin-right: 10px !important;
  }

  .speed-dial__button.speed-dial__button--primary {
    border-top-right-radius: 0px;
    pointer-events: all;
    border-bottom-right-radius: 0px !important;
  }

  .speed-dial__button {
    border-top-right-radius: 0px;
    pointer-events: all;
    border-bottom-right-radius: 0px;
  }

  h1.el_header > span {
    display: flex;
    flex-wrap: wrap;
  }

  h1.el_header > span > div {
    flex: 1 1 calc(50% - 20px);
  }

  .active.products.selected-product .full-size {
    top: 0px;
    position: absolute;
  }

  .pr__close-btn {
    position: fixed;
    right: 20px;
    top: 20px;
  }

  .products.active .el_header.hidden {
    display: none;
  }

  .el_products_inner {
    padding-bottom: 0px;
  }

  .pr__page_banner {
    flex: unset;
  }

  .pr__page_content {
    flex: unset;
  }

  .pr__page {
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 0px;
    height: 100%;
    height: auto;
    transform: none;
  }

  .pr__page_content {
    padding: 15px 20px;
    font-size: 0.9rem;
  }

  .price--buy {
    font-size: 0.7rem;
    align-items: baseline;
  }

  .pr__buy button {
    padding: 7px 30px;
  }

  .el.s--active .pr__close-btn {
    width: 35px;
    height: 35px;
    background-color: white;
    border-radius: 0px;
    top: 0px;
    right: 10px;
  }

  .pr__close-btn:before,
  .pr__close-btn:after {
    padding: 0;
    margin-top: unset;
    width: 75%;
    height: 3px;
    margin-top: -2px;
    margin-left: 5px;
  }
}

.LazyLoad.is-visible.hidden {
  display: none;
}

/*figure img {*/
/*  max-width: calc(100vw - 90px);*/
/*  height: auto;*/
/*}*/

/*.pr__buy {padding-top: 0.9rem;}*/

/*.pr__buy button {*/
/*  padding: 7px 50px;*/
/*  border: unset;*/
/*  background: green;*/
/*  font-size:12pt;*/
/*  color: white;*/
/*  margin-top: 10px;*/
/*  cursor: pointer;*/
/*}*/

/*@media screen and (max-width: 992px) {*/
/*  .pr_el {*/
/*      height: 300px;*/
/*      max-height: 300px;*/
/*      !* min-height: 100%; *!*/
/*  }*/
/*  .pr_category {*/
/*      margin-top: 10px;*/
/*  }*/
/*  .pr__page_content .pr__text {*/
/*      overflow-y: scroll;*/
/*      height: auto;*/
/*  }*/
/*}*/
