.cont {
  position: relative;
  overflow: hidden;
  height: 100vh;
}

.cont.s--el-active .el__bg:after {
  background: rgba(0, 0, 0, 0.75);
}

.cont__inner {
  position: relative;
  height: 100%;
}

.cont__inner:hover .el__bg:after {
  opacity: 1;
}

@media screen and (max-width: 992px) {
  .el__text h1 {
    margin-top: 20px;
  }

  .el__text {
    justify-content: start !important;
  }
}

@media screen and (max-width: 768px) {
  .speed-dial {
    top: 120px !important;
  }

  .speed-dial__button--primary {
    padding: 7px !important;
  }

  .pr__page {
    height: 100% !important;
    left: 10px !important;
    right: 10px !important;
  }
  .pr__page_content {
    height: 100% !important;
  }
  .pr__page_banner {
    max-height: 400px;
    overflow: hidden;
    padding-bottom: 75%;
    height: 0px;
  }
}

.dropdown .button {
  font-size: 14px !important;
  z-index: 9 !important;
  position: relative !important;
}

.products.active .el_header .dropdown {
  width: 230px !important;
  padding-right: 0px !important;
  background: rgba(0, 255, 0, 0.35) !important;
  border-radius: 5px !important;
}

.dropdown .button:before {
  right: 50% !important;
  transform: translateX(50%) !important;
  border: 8px solid !important;
  border-color: rgba(0, 255, 0, 0.35) transparent transparent transparent !important;
  top: unset !important;
  bottom: -16px !important;
}

.dropdown li {
  padding: 10px 5px !important;
  font-size: 14px !important;
}

.dropdown ul {
  width: 230px !important;
  top: 38px !important;
  z-index: 2 !important;
  padding-bottom: 4px !important;
  padding-top: 4px !important;
  border-radius: 5px !important;
  /* position: relative; */
}

.el {
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  background: #252525;
  transition: transform 0.6s 0.7s, width 0.7s, opacity 0.6s 0.7s,
    z-index 0s 1.3s;
  will-change: transform, width, opacity;
}

.el:not(.s--active) {
  cursor: pointer;
}

.el__overflow {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.el__inner {
  overflow: hidden;
  position: relative;
  height: 100%;
  transition: transform 1s;
}

.cont.s--inactive .el__inner {
  transform: translate3d(0, 100%, 0);
}

.el__bg {
  position: relative;
  width: calc(100vw);
  height: 100%;
  transition: transform 0.6s 0.7s;
  will-change: transform;
}

.el__bg:before {
  content: '';
  position: absolute;
  left: 0;
  top: -5%;
  width: 100%;
  height: 110%;
  background-size: cover;
  background-position: center center;
  transition: transform 1s;
  transform: translate3d(0, 0, 0) scale(1);
}

.cont.s--inactive .el__bg:before {
  transform: translate3d(0, -100%, 0) scale(1.2);
}

.el.s--active .el__bg:before {
  transition: transform 0.8s;
}

.el__bg:after {
  content: '';
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 105%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: opacity 0.5s;
}

.cont.s--el-active .el__bg:after {
  transition: opacity 0.5s 1.4s;
  opacity: 1 !important;
}

.el__preview-cont {
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s 1.2s;
}

.cont.s--inactive .el__preview-cont {
  opacity: 0;
  transform: translateY(10px);
}

.cont.s--el-active .el__preview-cont {
  opacity: 0;
  /* transform: translateY(30px); */
  transition: all 0.5s;
}

.el__heading {
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: normal;
  text-shadow: 1px 1px #4c4c4c;
}

.el__content {
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  overflow: hidden;
  height: 100%;
  padding: 1.2rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  flex-direction: column;
  pointer-events: none;
  transition: all 0.1s linear;
}

.el__text.active {
  transform: scale(1);
  line-height: 1.4em;
  margin-bottom: 1rem;
}

.el.s--active .el__content {
  z-index: 2;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.5s 1.4s;
}

.el__text {
  font-size: 1rem;
  color: #fff;
  max-width: 1000px;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  transform: scale(0);
  transition: all 0.7s ease;
}

.el__text > div {
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  padding-right: 20px;
  padding-left: 20px;
  width: 100%;
}

.el__close-btn {
  z-index: -1;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 50px;
  height: 50px;
  opacity: 0;
  pointer-events: none;
  transition: all 0s 0.45s;
  cursor: pointer;
}

.el.s--active .el__close-btn {
  z-index: 5;
  opacity: 1;
  pointer-events: auto;
  transition: all 0s 1.4s;
}

.el__close-btn:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 2rem;
  height: 2rem;
  border-left: 8px solid white;
  border-top: 8px solid white;
  margin-top: -4px;
  opacity: 0;
  transition: opacity 0s;
  transform: rotate(-45deg) translate(15px, 15px);
}

.active.products.selected-product {
  max-width: 1200px;
}

@media screen and (max-width: 1000px) {
  .el__close-btn:before {
    width: 1.3rem;
    height: 1.3rem;
  }
}

.el.s--active .el__close-btn:before,
.el.s--active .el__close-btn:after {
  opacity: 1;
}

.el__index {
  overflow: hidden;
  position: absolute;
  left: 0;
  bottom: -80px;
  width: 100%;
  height: 100%;
  min-height: 250px;
  text-align: center;
  font-size: 20vw;
  line-height: 0.85;
  font-weight: bold;
  transition: transform 0.5s, opacity 0.3s 1.4s;
  transform: translate3d(0, 1vw, 0);
}

.el:hover .el__index {
  transform: translate3d(0, 0, 0);
}

.cont.s--el-active .el__index {
  transition: transform 0.5s, opacity 0.3s;
  opacity: 0;
}

.el__index-back,
.el__index-front {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.el__index-back {
  color: #2f3840;
  opacity: 0;
  transition: opacity 0.25s 0.25s;
}

.el:hover .el__index-back {
  transition: opacity 0.25s;
  opacity: 1;
}

.el__index-overlay {
  overflow: hidden;
  position: relative;
  transform: translate3d(0, 100%, 0);
  transition: transform 0.5s 0.1s;
  color: transparent;
}

.el__index-overlay:before {
  content: attr(data-index);
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  transform: translate3d(0, -100%, 0);
  transition: transform 0.5s 0.1s;
}

.el:hover .el__index-overlay {
  transform: translate3d(0, 0, 0);
}

.el:hover .el__index-overlay:before {
  transform: translate3d(0, 0, 0);
}

.el:nth-child(1) {
  transform: translate3d(0%, 0, 0);
  transform-origin: 50% 50%;
}

.cont.s--el-active .el:nth-child(1):not(.s--active) {
  transform: scale(0.5) translate3d(0%, 0, 0);
  opacity: 0;
  transition: transform 0.95s, opacity 0.95s;
}

.el:nth-child(1) .el__inner {
  transition-delay: 0s;
}

.el:nth-child(1) .el__bg {
  transform: translate3d(0%, 0, 0);
}

.el:nth-child(1) .el__bg:before {
  transition-delay: 0s;
  background-image: inherit;
}

.el:nth-child(2) {
  transform: translate3d(100%, 0, 0);
  transform-origin: 150% 50%;
}

.cont.s--el-active .el:nth-child(2):not(.s--active) {
  transform: scale(0.5) translate3d(100%, 0, 0);
  opacity: 0;
  transition: transform 0.95s, opacity 0.95s;
}

.el:nth-child(2) .el__inner {
  transition-delay: 0.1s;
}

.el:nth-child(2) .el__bg {
  transform: translate3d(-19.2%, 0, 0);
}

.el:nth-child(2) .el__bg:before {
  transition-delay: 0.1s;
  background-image: inherit;
}

.el:nth-child(3) {
  transform: translate3d(200%, 0, 0);
  transform-origin: 250% 50%;
}

.cont.s--el-active .el:nth-child(3):not(.s--active) {
  transform: scale(0.5) translate3d(200%, 0, 0);
  opacity: 0;
  transition: transform 0.95s, opacity 0.95s;
}

.el:nth-child(3) .el__inner {
  transition-delay: 0.2s;
}

.el:nth-child(3) .el__bg {
  transform: translate3d(-38.4%, 0, 0);
}

.el:nth-child(3) .el__bg:before {
  transition-delay: 0.2s;
  background-image: inherit;
}

.el:nth-child(4) {
  transform: translate3d(300%, 0, 0);
  transform-origin: 350% 50%;
}

.cont.s--el-active .el:nth-child(4):not(.s--active) {
  transform: scale(0.5) translate3d(300%, 0, 0);
  opacity: 0;
  transition: transform 0.95s, opacity 0.95s;
}

.el:nth-child(4) .el__inner {
  transition-delay: 0.3s;
}

.el:nth-child(4) .el__bg {
  transform: translate3d(-35.6%, 0, 0);
}

.el:nth-child(4) .el__bg:before {
  transition-delay: 0.3s;
  background-image: inherit;
}

.el:nth-child(5) {
  transform: translate3d(400%, 0, 0);
  transform-origin: 450% 50%;
}

.cont.s--el-active .el:nth-child(5):not(.s--active) {
  transform: scale(0.5) translate3d(400%, 0, 0);
  opacity: 0;
  transition: transform 0.95s, opacity 0.95s;
}

.el:nth-child(5) .el__inner {
  transition-delay: 0.4s;
}

.el:nth-child(5) .el__bg {
  transform: translate3d(-76.8%, 0, 0);
}

.el:nth-child(5) .el__bg:before {
  transition-delay: 0.4s;
  background-image: inherit;
}

.el:nth-child(6) {
  transform: translate3d(500%, 0, 0);
  transform-origin: 550% 50%;
}

.cont.s--el-active .el:nth-child(6):not(.s--active) {
  transform: scale(0.5) translate3d(500%, 0, 0);
  opacity: 0;
  transition: transform 0.95s, opacity 0.95s;
}

.el:nth-child(6) .el__inner {
  transition-delay: 0.4s;
}

.el:nth-child(6) .el__bg {
  transform: translate3d(-96%, 0, 0);
}

.el:nth-child(6) .el__bg:before {
  transition-delay: 0.4s;
  background-image: inherit;
}

.el:nth-child(7) {
  transform: translate3d(600%, 0, 0);
  transform-origin: 650% 50%;
}

.cont.s--el-active .el:nth-child(7):not(.s--active) {
  transform: scale(0.5) translate3d(600%, 0, 0);
  opacity: 0;
  transition: transform 0.95s, opacity 0.95s;
}

.el:nth-child(7) .el__inner {
  transition-delay: 0.4s;
}

.el:nth-child(7) .el__bg {
  transform: translate3d(-96%, 0, 0);
}

.el:nth-child(7) .el__bg:before {
  transition-delay: 0.4s;
  background-image: inherit;
}

.el:nth-child(8) {
  transform: translate3d(700%, 0, 0);
  transform-origin: 750% 50%;
}

.cont.s--el-active .el:nth-child(8):not(.s--active) {
  transform: scale(0.5) translate3d(700%, 0, 0);
  opacity: 0;
  transition: transform 0.95s, opacity 0.95s;
}

.el:nth-child(8) .el__inner {
  transition-delay: 0.4s;
}

.el:nth-child(8) .el__bg {
  transform: translate3d(-96%, 0, 0);
}

.el:nth-child(8) .el__bg:before {
  transition-delay: 0.4s;
  background-image: inherit;
}

/* */
.el:nth-child(9) {
  transform: translate3d(800%, 0, 0);
  transform-origin: 750% 50%;
}

.cont.s--el-active .el:nth-child(9):not(.s--active) {
  transform: scale(0.5) translate3d(700%, 0, 0);
  opacity: 0;
  transition: transform 0.95s, opacity 0.95s;
}

.el:nth-child(9) .el__inner {
  transition-delay: 0.4s;
}

.el:nth-child(9) .el__bg {
  transform: translate3d(-96%, 0, 0);
}

.el:nth-child(9) .el__bg:before {
  transition-delay: 0.4s;
  background-image: inherit;
}

.el:hover .el__bg:after {
  opacity: 0;
}

.el.s--active {
  z-index: 1;
  width: 100%;
  transform: translate3d(0, 0, 0);
  transition: transform 0.6s, width 0.7s 0.7s, z-index 0s;
}

.el.s--active .el__bg {
  transform: translate3d(0, 0, 0);
  transition: transform 0.6s;
}

.el.s--active .el__bg:before {
  transition-delay: 0.6s;
  transform: scale(1.1);
}

.icon-link {
  position: absolute;
  left: 5px;
  bottom: 5px;
  width: 32px;
}

.icon-link img {
  width: 100%;
  vertical-align: top;
}

.icon-link--twitter {
  left: auto;
  right: 5px;
}

.el__text h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  position: absolute;
  background: rgba(255, 255, 255, 0.3) !important;
  border-radius: 0px;
  margin-top: 20%;
  margin-bottom: 20%;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5) !important;
  border-radius: 5px;
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #fff;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  background: green;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  background: red;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  background: #333;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.animation-logo {
  position: absolute;
  width: 7rem;
}

.triple-spinner {
  display: block;
  position: relative;
  width: 15rem;
  height: 15rem;
  border-radius: 50%;
  border: 8px solid transparent;
  border-top: 8px solid white;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.triple-spinner::before,
.triple-spinner::after {
  content: '';
  position: absolute;
  border-radius: 50%;
  border: 8px solid transparent;
}
.triple-spinner::before {
  top: 1rem;
  left: 1rem;
  right: 1rem;
  bottom: 1rem;
  border-top-color: green;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3.5s linear infinite;
}
.triple-spinner::after {
  top: 2.5rem;
  left: 2.5rem;
  right: 2.5rem;
  bottom: 2.5rem;
  border-top-color: red;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loading-screen.hide {
  display: none;
}
.logo img {
  width: 150px;
}

.logo {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 999;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 240px;
  opacity: 1;
  pointer-events: none;
}

.speed-dial {
  position: fixed;
  top: 20%;
  right: 15px;
  z-index: 1;
  width: 170px;
  display: flex;
  transform: translateY(-50%);
  z-index: 1;
  justify-content: flex-end;
}

.speed-dial__options {
  position: absolute;
  top: 100%;
  display: none;
  width: 100%;
  text-align: right;
  padding-right: 5px;
  margin-top: 10px;
}

.speed-dial__button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  margin: 10px;
  margin-right: 0px;
  background: #cccccc;
  color: #333333;
  border-radius: 50%;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  border: none;
  outline: none;
  transition: background 0.2s, opacity 0.2s, transform 0.2s;
}

.speed-dial__button:active {
  background: #aaaaaa;
}

.speed-dial__button--primary {
  margin-bottom: 0;
  padding: 10px;
  background: #ffffff;
  color: #ffffff;
  opacity: 0.95;
  animation: shadow-pulse 3s infinite;
}

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.35);
  }
  33% {
    box-shadow: 0 0 0 25px rgba(255, 255, 255, 0);
  }
  34% {
    box-shadow: 0 0 0 0px rgba(0, 255, 0, 0.35);
  }
  66% {
    box-shadow: 0 0 0 25px rgba(0, 255, 0, 0);
  }
  67% {
    box-shadow: 0 0 0 0px rgba(255, 0, 0, 0.35);
  }
  100% {
    box-shadow: 0 0 0 25px rgba(255, 0, 0, 0);
  }
}

@keyframes shadow-pulse-small {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.45);
  }
  100% {
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 0);
  }
}

.speed-dial__button--primary:hover {
  opacity: 1;
}

.speed-dial__button--primary:active {
  background: #00856a;
}

.speed-dial__button--holder:not(.speed-dial__button--primary) {
  opacity: 0;
  transform: translateY(40px);
  visibility: hidden;
}

.speed-dial--active .speed-dial__options {
  display: block;
}

.speed-dial--active .speed-dial__button--holder {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 0px;
}
.backdrop.show {
  display: block;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 1;
}
.cart-updated {
  display: block;
  position: fixed;
  bottom: 20px;
  left: 50%;
  background: green;
  color: white;
  box-shadow: 2px 2px 10px 0px black;
  z-index: 100;
  padding: 10px 20px;
  transform: translateX(-50%);
}

.speed-dial.hidden {
  display: none;
}
iframe:not([name^='blank_']) {
  background: rgba(255, 255, 255, 1);
  padding: 10px;
}

.content-collapsable {
  padding: 0rem 1rem 0rem;
  max-height: 0;
  font-size: 1rem;
  overflow: hidden;
  border-left: 5px solid rgba(255, 255, 255, 0.3);
  margin-left: 3px;
  transition: max-height 0.2s ease-out;
}

p.collapsible {
  background-color: transparent;
  color: #88bdff;
  cursor: pointer;
  padding: 1rem 0rem;
  width: auto;
  border: none;
  text-align: left;
  outline: none;
}

.language-switcher {
  display: block;
  position: absolute;
  z-index: 99;
  top: 15px;
  left: 10px;
  animation: shadow-pulse-small 1s infinite;
}

.language-switcher a {
  background: white;
  padding: 5px 10px;
  color: green;
  text-decoration: none;
  font-weight: bold;
  box-shadow: 0 0 3px 2px rgba(0, 128, 0, 0.5);
}

input.error,
textarea.error {
  box-shadow: none !important;
  /* border-color: inherit!important; */
  /* background-color: #ff00005e!important; */
}

span.form-error {
  display: block;
  color: red;
  font-size: 0.7rem;
  margin-top: -0.1rem;
  position: absolute;
  right: 0;
  top: 1px;
  font-weight: bold;
}

label {
  position: relative;
}

input[type='checkbox'] {
  display: block;
  height: 25px;
}

.additional_pages {
  position: absolute;
  bottom: 0px;
  left: 40px;
}

.terms,
.gdpr {
  font-size: 0.8em;
  color: white;
  display: inline-block;
  margin-left: 5px;
  padding: 5px 10px;
  background: rgba(0, 0, 0, 0.5);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
  z-index: 9;
}
