.three-columns {
  display: flex;
  flex-direction: row;
}

.three-columns h3 {
  padding-bottom: 1rem;
}

.three-columns .form {
  padding-right: 20px;
}

.three-columns .map {
  padding-left: 20px;
}

.three-columns a {
  color: #6fcaff!important;
  text-decoration: none;
}

.three-columns .form, .three-columns .map {
  flex: 1 1 auto;
}

.three-columns > div:not(.form):not(.map) {
  max-width: 400px;
}

@media screen and (max-width: 992px) {
  .three-columns {
    flex-direction: column;
  }

  .three-columns > div {
      margin-top: 50px;
      padding: 0px!important;
  }

  .three-columns > div > iframe {
      width: 100%;
  }
}