.pr_el {
  max-height: 300px;
  height: 44vh;
  background-color: #f0f0f0;
  min-width: 230px;
  max-width: 300px;
  flex: 0 1 calc(25% - 30px);
  cursor: pointer;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s, visibility 0.5s, opacity 0.5s linear;
}
.lazy-load {
  flex: 1 1 calc(14% - 30px);
  margin: 15px;
  align-items: flex-start;
  justify-content: center;
  display: flex;
}

.pr_el:hover {
  box-shadow: 0px 0px 0px 5px gold;
}

.pr_banner {
  position: relative;
}

.pr_banner img {
  width: 100%;
}

.pr_title {
  text-align: center;
  font-size: 0.85em;
  margin: 15px 10px 0px;
  color: #333;
  flex: 1;
  font-weight: bold;
  min-height: 4rem;
}

.pr_category {
  text-align: center;
  margin: 0px 0px 15px;
  color: #054814;
  font-size: 14px;
  min-height: 2rem;
}

.pr_price {
  text-align: center;
  color: #17e646;
}

.pr_el.inactive,
.pr_el.inactive:hover {
  filter: grayscale(1);
}

.pr_el .pr_banner img {
  filter: grayscale(0);
  transition: filter 0.5s ease-in-out;
}

.pr_el:hover .pr_banner img {
  filter: grayscale(0.7);
}

.pr_el.inactive .pr_status {
  display: block;
}

.pr_status {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  color: white;
  transform: translate(-50%, -50%);
  font-weight: bold;
  opacity: 0.7;
  z-index: 1;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.image {
  width: 100%;
  padding-top: 62.4%;
}

.text {
  width: 70%;
  margin-left: 15%;
  height: 20px;
  text-align: center;
}

.text-line {
  height: 10px;
  max-height: 20px;
  margin-top: 20px;
  width: 100%;
}

.text-line.price {
  width: 60%;
  margin: 15px auto 0px;
}

.animated-background {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #e6e6e6;
  background: linear-gradient(to right, #e6e6e6 8%, #dadada 18%, #e6e6e6 33%);
  background-size: 650px 104px;
  height: 96px;
  position: relative;
}

.pr_el.hidden {
  display: none;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
}

.el_products_inner {
  position: relative;
}
